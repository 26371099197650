
import { defineComponent } from 'vue';
import PageHeaderFixed from '@/components/Allgemein/PageHeaderFixed.vue';
import PageLoader from '@/components/Loading/PageLoader.vue';
import { LoadingRequestedData } from '@/api/Loading';
import { BaseData } from '@/data_models/BaseData';

export default defineComponent({
  name: 'Impressum',
  components: {
    PageLoader,
    PageHeaderFixed
  },
  data () {
    return {
      loadingDataTargets: [{ target: 'DatenschutzData', ids: null }] as LoadingRequestedData,
      baseData: BaseData
    };
  }
});

