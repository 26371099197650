
import { defineComponent } from 'vue';
import PageHeaderFixed from '@/components/Allgemein/PageHeaderFixed.vue';
import { LoadingRequestedData } from '@/api/Loading';
import { BaseData } from '@/data_models/BaseData';
import PageLoader from '@/components/Loading/PageLoader.vue';

export default defineComponent({
  name: 'Impressum',
  components: {
    PageLoader,
    PageHeaderFixed
  },
  data () {
    return {
      loadingDataTargets: [{ target: 'ImpressumData', ids: null }] as LoadingRequestedData
    };
  },
  computed: {
    imprintIntroductionHtml (): string {
      return BaseData.impressum?.introduction ?? '';
    },
    imprintContactHtml (): string {
      return BaseData.impressum?.imprint_contact ?? '';
    }
  }
});

