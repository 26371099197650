import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e91bdc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datenschutz" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header_fixed = _resolveComponent("page-header-fixed")!
  const _component_PageLoader = _resolveComponent("PageLoader")!

  return (_openBlock(), _createBlock(_component_PageLoader, { "loading-data-targets": _ctx.loadingDataTargets }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_page_header_fixed),
        _createElementVNode("main", null, [
          _createElementVNode("article", {
            innerHTML: _ctx.baseData.datenschutz?.privacy_statement ?? ''
          }, null, 8, _hoisted_2)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading-data-targets"]))
}